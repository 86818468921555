import { IncomingHttpHeaders } from 'http';
import Environment from '@config/environment';

type HeaderBag = Record<string, string>;

export default class HeaderPropagationService {
  static extractHeaders(incomingHttpHeaders?: IncomingHttpHeaders): HeaderBag {
    const headerBag: HeaderBag = {};

    Environment.propagatedHeaders.forEach((headerName: string) => {
      if (incomingHttpHeaders[headerName]) {
        headerBag[headerName] = <string>incomingHttpHeaders[headerName];
      }
    });

    return headerBag;
  }
}
