import { datadogRum } from '@datadog/browser-rum-slim';

const logDatadogError = (errorMessage: string) => {
  datadogRum.addError(errorMessage);
};

export const checkDataLayer = () => {
  // Rakuten tag critical datalayer and env_template check
  const datalayer = window?.tc_vars;

  if (datalayer?.order_confirmation_checkout_flow) {
    // check one time after payment
    if (!Array.isArray(datalayer?.order_products)) {
      logDatadogError(`order_products datalayer type is not an array on ${datalayer?.env_template} page`);
    } else if (datalayer?.order_products?.length === 0) {
      logDatadogError(`order_products datalayer is empty on ${datalayer?.env_template} page`);
    }
  }
  // product_id check on product page
  if (datalayer?.env_template === 'product') {
    if (!datalayer?.product_id) {
      logDatadogError(`product_id datalayer is not populated on ${datalayer?.env_template} page`);
    }
  }
};
