import parseBoolean from '@helpers/utils/boolean-parser';
import { Env } from '@interfaces/models/environment';

const apiExternalUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
// the internal url may be different from the external name (localdev)
const apiInternalUrl = process.env.NEXT_PUBLIC_API_INTERNAL_URL || apiExternalUrl;
const apiBaseUrl = typeof window === 'undefined' ? apiInternalUrl : apiExternalUrl;

const Environment: Env = {
  envName: process.env.NEXT_PUBLIC_ENV_NAME,
  legacyLink: process.env.NEXT_PUBLIC_MONOLITH_BASE_URL,
  apiBaseUrl,
  baseHost: process.env.NEXT_PUBLIC_BASE_HOST,
  checkoutBaseUrl: process.env.NEXT_PUBLIC_CHECKOUT_BASE_URL,
  apiStaticBaseUrl: process.env.NEXT_PUBLIC_API_STATIC_BASE_URL,
  apiStaticSSRBaseUrl:
    process.env.NEXT_PUBLIC_ENV_NAME === 'production'
      ? process.env.NEXT_PUBLIC_API_STATIC_BASE_URL?.replace('s3', 'internal')
      : process.env.NEXT_PUBLIC_API_STATIC_BASE_URL,
  apiProductSearchBaseUrl: process.env.NEXT_PUBLIC_SEARCH_BASE_URL,
  imagesBaseUrl: process.env.NEXT_PUBLIC_IMAGE_BASE_URL,
  imagesResizedPrefixStartingProduitId: Number(process.env.NEXT_PUBLIC_IMAGES_RESIZED_PREFIX_STARTING_PRODUIT_ID),
  imagesResizedPrefixEnabled: parseBoolean(process.env.NEXT_PUBLIC_IMAGES_RESIZED_PREFIX_ENABLED),
  assetsPath: process.env.NEXT_PUBLIC_ASSET_PATH,
  //assetsImages: process.env.NEXT_PUBLIC_ASSET_IMAGES,
  cdnFolder:
    process.env.NEXT_PUBLIC_ENV_NAME === 'production'
      ? `${process.env.NEXT_PUBLIC_CDN_FOLDER}/v${process.env.appVersion}`
      : process.env.NEXT_PUBLIC_ENV_NAME === 'teod'
        ? `https://assets.teod.vestiairecollective.com/${process.env.MERGE_REQUEST_ID}/a/fenx`
        : process.env.NEXT_PUBLIC_CDN_FOLDER,
  svgSpritePath: process.env.NEXT_PUBLIC_SVG_SPRITE_PATH,
  cookieSession: process.env.NEXT_PUBLIC_SESSION_COOKIE_NAME,
  cookieOptions: {
    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    path: '/',
  },
  cookieAnonymousUser: process.env.NEXT_PUBLIC_ANONYMOUS_USER_COOKIE_NAME,
  cookieAnonymousUserSsr: process.env.NEXT_PUBLIC_SSR_ANONYMOUS_USER_COOKIE_NAME,
  cookieUID: process.env.NEXT_PUBLIC_COOKIE_UID,
  propagatedHeaders: (process.env.VC_HEADER_PROPAGATION ?? '').split(';').filter(Boolean),
  analytics: {
    envName: process.env.NEXT_PUBLIC_ANALYTICS_ENV_NAME,
    verbose: parseBoolean(process.env.NEXT_PUBLIC_ANALYTICS_VERBOSE_LOGGING),
  },
  salesForcePostUrl: process.env.NEXT_PUBLIC_SALESFORCE_POST_URL,
  feature: {
    isClientSideNavigationEnabledGlobally: false,
  },
  launch_darkly: {
    sdkKey: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_SDK_KEY,
    clientSideID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID,
  },
  vccmsuri:
    (process.env.NEXT_PUBLIC_ENV_NAME === 'dev' ? '' : process.env.NEXT_PUBLIC_API_BASE_URL) +
    process.env.NEXT_PUBLIC_CMS_URI_PATH,
  vccmsimguri: process.env.NEXT_PUBLIC_CMS_IMAGE_BASE_URL,
  streamApiKey: process.env.NEXT_PUBLIC_STREAM_API_KEY,
  randomRangeId: process.env.NEXT_PUBLIC_RANDOM_RANGE_ID,
  disableAnalytics:
    process.env.NEXT_PUBLIC_ENV_NAME === 'dev' && parseBoolean(process.env.NEXT_PUBLIC_DISABLE_ANALYTICS),
  sunCoIntegrationId: process.env.NEXT_PUBLIC_SUNCO_INTEGRATION_ID,
  zendesk: {
    baseUrl: process.env.NEXT_PUBLIC_ZENDESK_BASE_URL,
    dsaPath: process.env.NEXT_PUBLIC_ZENDESK_DSA_PATH,
  },
  cloudflareCaptchaSecretKey: process.env.NEXT_CF_TURNSTILE_SECRET_KEY,
};

if (process.env.NEXT_PUBLIC_ENV_NAME !== 'dev' && process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN) {
  Environment.datadog = {
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
    site: process.env.NEXT_PUBLIC_DATADOG_SITE,
    service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
    env: process.env.NEXT_PUBLIC_DATADOG_ENV,
    sampleRate: 25,
    sampleRateBot: 5,
    tracingSampleRate: 25,
    replaySampleRate: 0,
    allowedTracingOrigins:
      process.env.NEXT_PUBLIC_ENV_NAME === 'production'
        ? [apiBaseUrl, /https:\/\/([a-z]{2,3})\.vestiairecollective\.com/, 'https://search.vestiairecollective.com']
        : [
            apiBaseUrl,
            /https:\/\/([a-z]{2,3})\.(qa|stage|teod|staging)\.vestiairecollective\.com/,
            /https:\/\/([a-z]{2,3})\.(mr[0-9]{4,6})\.fenx\.tests\.vestiairecollective\.com/,
            'https://search.preprod.vestiairecollective.com',
            /**
             * @deprecated
             */
            'https://apiv2.qa.vestiairecollective.com',
            'https://atlas.qa.vestiairecollective.com',
            'https://apiv2.staging.vestiairecollective.com',
            'https://atlas.staging.vestiairecollective.com',
          ],
  };
}

export default Environment;
