import { Price } from './price';

export interface NegotiationEvent {
  id: string;
  type: NegotiationEvent.TypeEnum;
  /**
   * Type of event. Can be one of the following:
   * - offer: offer or counter-offer
   * - accept: acceptation of an offer
   * - reject: rejection of an offer, without making a counter-offer
   * - order: action of buying the product (after payment and anti-fraud controls)
   * - external: external actions that directly impact the negotiation (for example: ! product bought by someone else)
   */
  action: NegotiationEvent.ActionEnum;
  /**
   * Date when event was created
   */
  creationDate: Date;
  /**
   * Role of the user who created the event
   */
  createdBy: NegotiationEvent.CreatedByEnum;
  /**
   * Displayable translated message that describes the status of the negotiation
   */
  userMessage: string;
  /**
   * Current offer price
   */
  price: Price;
  /**
   * PVN (Seller share). Only if the caller if the seller
   */
  sellerPvnPrice?: Price;
}
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NegotiationEvent {
  export enum TypeEnum {
    NegotiationEvent = 'negotiationEvent',
  }
  export enum ActionEnum {
    Offer = 'offer',
    Accept = 'accept',
    Reject = 'reject',
    Order = 'order',
    External = 'external',
  }
  export enum CreatedByEnum {
    Seller = 'seller',
    Buyer = 'buyer',
    Visitor = 'visitor',
    Mixed = 'mixed',
  }
}
