import { Negotiation } from '@interfaces/models/negotiation';
import { NegotiationEvent } from '@interfaces/models/negotiationEvent';
import { ApiResponse } from '@interfaces/api';
import Environment from '@config/index';
import { axios, getAxiosConfig, ServerAxiosRequestData } from '@config/axios';
import { Product } from '@interfaces/models/product';
import { User, UnavailabilityPayload } from '@interfaces/models/user';
import { UserI18n } from '@interfaces/models/i18n';
import { Env } from '@interfaces/models/environment';

export default class UserService {
  static apiBaseUrl: Env['apiBaseUrl'] = Environment.apiBaseUrl;

  static getUserNegotiationMmaoRole = (
    user: User,
    product: Product,
    negotiation: Negotiation,
  ): NegotiationEvent.CreatedByEnum => {
    let role: NegotiationEvent.CreatedByEnum;
    if (product && product.seller) {
      role =
        user.id === product.seller.id ? NegotiationEvent.CreatedByEnum.Seller : NegotiationEvent.CreatedByEnum.Buyer;
    } else if (negotiation) {
      role =
        user.id === negotiation.seller.id
          ? NegotiationEvent.CreatedByEnum.Seller
          : NegotiationEvent.CreatedByEnum.Buyer;
    }
    return role;
  };

  static async followUser(userId: User['id']): Promise<void> {
    const axiosConfig = getAxiosConfig();
    await axios.post<ApiResponse<string>>(`${this.apiBaseUrl}/users/${userId}/followers`, null, axiosConfig);
  }

  static async unfollowUser(userId: User['id']): Promise<void> {
    const axiosConfig = getAxiosConfig();
    await axios.delete(`${this.apiBaseUrl}/users/${userId}/followers/me`, axiosConfig);
  }

  static async updateUserSettings(payload: UserI18n): Promise<UserI18n | Error> {
    const axiosConfig = getAxiosConfig();

    try {
      const { data } = await axios.patch<UserI18n>(`${this.apiBaseUrl}/users/me/settings`, payload, axiosConfig);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getUser(userId: User['id'], serverAxiosRequestData?: ServerAxiosRequestData): Promise<User | null> {
    const axiosConfig = getAxiosConfig(serverAxiosRequestData);
    const {
      data: { data },
    } = await axios.get<ApiResponse<User | null>>(`${this.apiBaseUrl}/users/${userId}`, axiosConfig);
    return data;
  }

  static async setUnavailability(payload: UnavailabilityPayload) {
    const axiosConfig = getAxiosConfig();
    await axios.post(`${this.apiBaseUrl}/users/me/unavailability`, payload, axiosConfig);
  }
  static async updateUnavailability(payload: UnavailabilityPayload) {
    const axiosConfig = getAxiosConfig();
    await axios.put(`${this.apiBaseUrl}/users/me/unavailability`, payload, axiosConfig);
  }
  static async deleteUnavailability() {
    const axiosConfig = getAxiosConfig();
    await axios.delete(`${this.apiBaseUrl}/users/me/unavailability`, axiosConfig);
  }
}
