import { GetServerSidePropsContext, NextPageContext } from 'next';
import Cookies from 'universal-cookie';
import PreferenceCookie from '@services/preferences-cookie-service';
import { AppCookies } from '@enums/cookies';
import HeaderPropagationService from '@services/header-propagation-service';
import { Preferences } from '@interfaces/models/preferences';
import { ServerAxiosRequestData } from '@config/axios';
import { CkCookieTemplate } from '@interfaces/models/cookie';

type NextContext = NextPageContext | GetServerSidePropsContext;

type ExtractFromContextReturn = {
  serverAnonymousId: string | null;
  clientAnonymousId: string | null;
  userId: string | null;
  sessionId: string | null;
  headersToPropagate: Record<string, string>;
  preferences: Preferences;
  // Helper property that can be passed directly to requests without having to worry about what specific data each requests needs.
  serverAxiosRequestData: Required<ServerAxiosRequestData>;
} & Preferences;

export const extractFromCookies = (
  cookies: Cookies,
): Omit<ExtractFromContextReturn, 'headersToPropagate' | 'serverAxiosRequestData'> => {
  const serverAnonymousId: string | null = cookies.get<string>(AppCookies.ANONYMOUS_USER_ID_SSR) ?? null;
  const clientAnonymousId: string | null = cookies.get<string>(AppCookies.ANONYMOUS_USER_ID_CSR) ?? null;
  const userId: string | null = cookies.get<string>(AppCookies.UID, { doNotParse: true }) ?? null;
  const sessionId: string | null = cookies.get<string>(AppCookies.SESSION) ?? null;
  const ckCookie = cookies.get<CkCookieTemplate>(AppCookies.CK) ?? '6.us.USD';
  const ccCookie = cookies.get<string>(AppCookies.CC) ?? 'eyJDQyI6IlVTIiwiZGlzcGxheU5hbWUiOnt9fQ'; // '{"CC":"US","displayName":{}}'
  const { language, locale, siteId, currency, regionName } = PreferenceCookie.parseCookieCK(ckCookie);
  const { country } = PreferenceCookie.parseCookieCC(ccCookie);
  const smartBannerClosed: boolean = cookies.get<boolean>(AppCookies.SMART_BANNER_CLOSED) ?? false;

  const preferences: Preferences = {
    language,
    locale,
    siteId,
    currency,
    regionName,
    country,
    smartBannerClosed,
  };

  return {
    serverAnonymousId,
    clientAnonymousId,
    userId,
    sessionId,
    preferences,
    ...preferences,
  };
};

const extractFromContext = (context: NextContext): ExtractFromContextReturn => {
  const headersToPropagate = HeaderPropagationService.extractHeaders(context?.req?.headers);

  const cookies = new Cookies(context?.req?.headers?.cookie);
  const { userId, sessionId, preferences, serverAnonymousId, clientAnonymousId } = extractFromCookies(cookies);
  return {
    userId,
    sessionId,
    serverAnonymousId,
    clientAnonymousId,
    preferences,
    headersToPropagate,
    serverAxiosRequestData: {
      sessionId,
      headersToPropagate,
      preferences,
    },
    ...preferences,
  };
};

export default extractFromContext;
