export enum GlobalQueryKeys {
  HEADER = 'global.header',
  PARTIAL_HEADER = 'global.partialHeader',
  DIRECT_SHIPPING_ELIGIBILITY = 'global.directShippingEligibility',
  SEARCH_POPULAR_BRANDS = 'search.popularBrands',
  SEARCH_SUGGESTIONS = 'search.suggestions',

  SIZE_GUIDE = 'global.sizeGuide',

  PAYMENT_LOGOS = 'global.paymentLogos',

  PRODUCT_CONDITION_DETAILS = 'global.productConditionDetails',
  PRODUCT_SALES_CONDITION = 'global.productSalesCondition',

  INITIAL_REQUEST_URL = 'global.initialRequestUrl',
  WELCOME_VOUCHER_ANONYMOUS_USER = 'global.welcomeVoucherAnonymousUser',
  PAGE_TYPE = 'global.pageType',
}

export enum ProductPageQueryKeys {
  SIMILAR_PRODUCTS = 'pdp.similarProducts',
  CHAT_ELIGIBLE = 'pdp.chatEligible',
  PRODUCT_SHIPPING = 'pdp.productShipping',
}

export enum UserQueryKeys {
  USER = 'user.user',
  USER_SIZES = 'user.userSizes',
  FOLLOWED_USERS = 'user.followedUsers',
  WELCOME_VOUCHER_LOGGED_IN_USER = 'user.welcomeVoucherLoggedInUser',
  LIKED_PRODUCTS = 'user.likedProducts',
}

export enum UserMutationKeys {
  FOLLOW_USER = 'user.followUser',
  UNFOLLOW_USER = 'user.unfollowUser',
}

export enum NotificationQueryKeys {
  NOTIFICATION_FEED_ACTIVITY = 'notification.feed.activity',
  NOTIFICATION_FEED_MESSAGES = 'notification.feed.messages',
  NOTIFICATION_FEED_COUNTER = 'notification.feed.counter',
  NOTIFICATION_FEED_COUNTER_V2 = 'notification.feed.counter.v2',
  NOTIFICATION_FEED_UPDATES_V2 = 'notification.feed.updates.v2',
  NOTIFICATION_FEED_MESSAGES_V2 = 'notification.feed.messages.v2',
  NOTIFICATION_FEED_RESET_COUNTER = 'notification.feed.reset.counter',
}

export enum ChatPageMessageListQueryKeys {
  CHAT_PAGE_MSG_LIST = 'chatPage.messageList',
  CHAT_PAGE_CHAT_HISTORY = 'chatPage.chatHistory',
}

export enum FooterQueryKeys {
  FOOTER_DATA = 'footer.data',
}

export enum CartQueryKeys {
  CART_ORDERS = 'cart.orders',
  CART_CONFIGS = 'cart.configs',
}

export enum CartMutationKeys {
  ADD_TO_CART = 'cart.addFromCart',
  REMOVE_FROM_CART = 'cart.removeFromCart',
}

export enum FooterI18NKeys {
  LANG_FOR_COUNTRY = 'i18n.languagesForCountry',
}

export enum ProductCatalogQueryKeys {
  SEARCH_PREFERENCE_OPTION = 'plp.searchPreferenceOptions',
  GET_PRODUCT_CATALOG = 'plp.getProductCatalog',
  GET_PLP_INNER_FEED = 'plp.getPlpInnerFeed',
}

export enum ProductDiscussionKeys {
  INITIAL_PRODUCT_DISCUSSION_COMMENTS = 'productDiscussion.initialProductDiscussionComments',
  PRODUCT_DISCUSSION_COMMENTS = 'productDiscussion.productDiscussionComments',
  ADD_PRODUCT_DISCUSSION_COMMENT = 'productDiscussion.addComment',
}

export enum NegotiationKeys {
  ACTIVE_NEGOTIATION = 'negotiation.active',
  NEGOTIATIONS_BY_PRODUCT = 'negotiation.byProduct',
}

export enum CTAProductQueryKeys {
  NEGOTIATION = 'CTAproduct.neogtiation',
}

export enum TranslationMutationKeys {
  TRANSLATE = 'productDiscussion.translate',
}

export enum CatalogManagementQueryKeys {
  CATALOG_LIST = 'catalogManagement.fetchCatalogList',
  CATALOG_PRODUCT = 'catalogManagement.fetchCatalogProduct',
  FILTERS = 'catalogManagement.fetchFilters',
}

export enum MySizesQueryKeys {
  SEARCH_PREFERENCE_OPTION = 'mysizes.searchPreferenceOptions',
}

export enum AlertsCatalogQueryKeys {
  FETCH_DATA = 'get.alertsNewInAndProductSearch',
  CHECK_EXISTENCE = 'check.alertsExistence',
}

export enum ListContextQueryKeys {
  LIST_MODELS = 'depositService.listModels',
  LIST_BRANDS = 'depositService.listBrands',
  LIST_CATALOG = 'depositService.listCatalog',
  PRODUCT_DRAFT_COUNT = 'depositService.getProductDraftCount',
}

export enum PricingCalculatorQueryKeys {
  PRICE_ESTIMATE = 'depositService.getPriceEstimate',
}

export enum JournalCatagoryKeys {
  GET_CATEGORIES = 'JournalMainService.getCategories',
  GET_ARTICLES_BY_CATEGORY = 'JournalMainService.getArticlesByCategory',
}

export enum ActiveDealQueryKeys {
  ACTIVE_DEAL = 'activeDeal.activeDeal',
}

export enum LocalizationQueryKeys {
  LOCALIZATION = 'localization.localization',
}

export enum MarketingFiltersQueryKeys {
  MARKETING_FILTERS = 'marketingFilters.marketingFilters',
}

export enum ProductPdpPageQueryKeys {
  GET_PDP_PRODUCT = 'productPdp.getPdpProduct',
}

export enum CatalogPageQuerykeys {
  GET_CATALOG_METADATA = 'catalog.getCatalogPageMetadata',
  GET_GUIDED_SEARCH = 'catalog.getGuidedSearch',
}

export enum HeroPdpPageQueryKeys {
  GET_SIMILAR_PRODUCTS = 'heroPdp.similarProducts',
}

export enum BreadcrumbsQueryKeys {
  BREADCRUMBS = 'breadcrumbs.breadcrumbs',
}

export enum OrdersSalesQueryKeys {
  ORDERS = 'ordersSales.orders',
  SALES = 'ordersSales.sales',
  TIMELINE = 'ordersSales.timeline',
  SEARCH_SALES = 'ordersSales.searchSales',
  PRODUCT_SHIPPING = 'ordersSales.productShipping',
}

export enum FavoritesQueryKeys {
  FAVORITES_PRODUCTS = 'favorites.getProducts',
}

export enum RecentSearchesQueryKeys {
  RECENT_SEARCHES_COUNT = 'recentSearches.recentSearchesCount',
}

export enum OrderReportIssueQueryKeys {
  UPLOAD_IMAGE = 'reportIssue.uploadImage',
  SUBMIT_RETURN_FORM = 'reportIssue.submitReturnForm',
}

export enum OrderCallbackQueryKeys {
  SUBMIT_FORM = 'callback.submitForm',
}

export enum PayoneerKeys {
  GET_PAYONEER_SUPPORTED_BANKS = 'payoneer.getPayoneerSupportedBanks',
}

export enum KycQueryKeys {
  GET_CUSTOMER = 'kyc.getCustomer',
  GET_TRANSFER_METHOD = 'kyc.getTransferMethod',
}

export enum UserAuthenticationKeys {
  UPDATE_PASSWORD = 'userAuthentication.updatePassword',
}

export enum ListingMutationQueryKeys {
  REMOVE_FROM_SALE = 'listing.removeFromCart',
  REASONS_TO_CANCEL_MY_SALE = 'listing.reasonsToCancelMySale',
  CANCEL_MY_SALE = 'listing.cancelMySale',
}

export enum SellerInformationKeys {
  GET_BILLING_ADDRESS = 'SellerInfoService.getUserBillingAddress',
  GET_TAX_INFORMATION = 'SellerInfoService.getUserTaxInformation',
  UPDATE_TAX_INFORMATION = 'SellerInfoService.updateUserTaxInformation',
  SAVE_TAX_INFORMATION = 'SellerInfoService.saveUserTaxInformation',
}

export enum ProfileQueryKeys {
  PROFILE_USER = 'profile.getProfile',
  PROFILE_MY_ITEMS_FOR_SALE = 'profile.myItemsForSale',
  PROFILE_COMMUNITY_FOLLOWING = 'profile.community.following',
  PROFILE_COMMUNITY_FOLLOWERS = 'profile.community.followers',
}

export enum ProductFeedQueryKeys {
  PDP_PRODUCT_FEEDS = 'productFeed.pdpProductFeeds',
}
